












import Vue from 'vue'
import { debounce } from 'lodash'
import SelectInput from '@/components/dataTable/components/SelectInput.vue'
import TextInput from '@/components/dataTable/components/TextInput.vue'
import DateTimePicker from '@/components/inputs/DateTimePicker.vue'
import { CombinedVueInstance } from 'vue/types/vue'

//TODO refactor: move clearable attribute to $attributes, move label to attributes?, is source needed?
export default Vue.extend({
	name: 'InputFilter',
	components: {
		SelectInput,
		TextInput,
		DateTimePicker,
	},
	props: {
		type: {
			type: String,
			required: true,
			validator: (v) => ['SelectInput', 'TextInput', 'DateTimePicker'].includes(v),
		},
		label: String,
		source: String,
		defaultValue: {
			default: () => null,
		},
		value: {
			default: () => null,
		},
	},
	data() {
		return {
			internalValue: this.value,
		}
	},
	watch: {
		value: {
			handler(val) {
				this.internalValue = val
			},
			immediate: true,
		},
	},
	methods: {
		update(value: any) {
			this.debounceInput(value)
		},
		//https://stackoverflow.com/questions/67159825/how-do-i-typecast-a-debounced-computed-setter-in-vue2
		debounceInput: debounce(function (
			this: CombinedVueInstance<Vue, any, unknown, unknown, unknown>,
			value: any,
		): void {
			this.internalValue = value
			this.$emit('input', this.internalValue)
		},
		600),
	},
})
