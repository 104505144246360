










import Vue from 'vue';

export default Vue.extend({
  name: 'TextInput',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    appendIcon: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    update(value: Array<string|number>): void {
      this.$emit('input', value)
    },
  }
})
