











































//@ts-nocheck
import Vue, { PropType } from 'vue'
import { formatDate } from '@/utils/helpers'

//TODO solution with value is garbage need refactoring, now working as intended
export default Vue.extend({
	name: 'DateTimePicker',
	props: {
		value: {
			type: Array as PropType<Array<string>>,
		},
	},
	data() {
		return {
			menu: false,
			loading: false,
			dateTo: '',
			dateFrom: '',
		}
	},
	computed: {
		formattedDate(): string | null {
			return `${formatDate(this.dateFrom, 'YYYY/MM/DD')} - ${formatDate(this.dateTo, 'YYYY/MM/DD')}`
		},
		getDate(): Array<string> {
			return this.value
		},
	},
	watch: {
		value() {
			this.setDate()
		},
	},
	methods: {
		update(): void {
			const value = [this.dateFrom, this.dateTo]
			this.$emit('input', value)
			this.$emit('change', value)

			this.menu = false
		},
		setDate(): void {
			if (this.value) {
				this.dateFrom = this.value[0]
				this.dateTo = this.value[1]
			}
		},
		formatDate: formatDate,
	},
	mounted() {
		this.$nextTick(() => {
			this.setDate()
		})
	},
})
