
































import Vue, { PropType } from 'vue'
import { IFilter } from '@/types/table'
import InputFilter from '@/components/dataTable/components/InputFilter.vue'

export default Vue.extend({
	name: 'FormFilter',
	components: {
		InputFilter,
	},
	props: {
		value: {
			type: Object,
			default: () => ({}),
		},
		filters: {
			type: Array as PropType<IFilter[]>,
			default: () => [],
		},
	},
	methods: {
		remove(filter: IFilter): void {
			let value = { ...this.value }

			delete value[filter.source]

			this.$emit('remove', filter)
			this.$emit('input', value)
		},
		update(item: IFilter, value: string): void {
			this.$emit('input', {
				...this.value,
				[item.source]: value,
			})
		},
	},
})
