





















import Vue, { PropType } from 'vue'
import { IChoice } from '@/types/select'

export default Vue.extend({
	name: 'SelectInput',
	props: {
		multiple: {
			type: Boolean,
			default: () => false,
		},
		chips: {
			type: Boolean,
			default: () => true,
		},
		smallChips: {
			type: Boolean,
			default() {
				return this.multiple === true
			},
		},
		label: {
			type: String,
		},
		clearable: {
			type: Boolean,
		},
		source: {
			type: String,
		},
		choices: {
			type: Array as PropType<Array<IChoice>>,
			default: () => null,
		},
		value: {
			type: [Array, String] as PropType<Array<string | number> | string | number>,
		},
	},
	methods: {
		update(value: Array<string | number>): void {
			this.$emit('input', value)
		},
	},
})
